// pages/Portfolio.js
import React from 'react';
import ProjectCard from '../components/ProjectCard';
import '../css/Portfolio.css';

const Portfolio = () => {
  const projects = [
    { title: 'Project 1', description: 'Description of project 1', link: '/project1' },
    { title: 'Project 2', description: 'Description of project 2', link: '/project2' },
    // Add more projects as needed
  ];

  return (
    <div className="portfolio">
      {projects.map((project, index) => (
        <ProjectCard
          key={index}
          title={project.title}
          description={project.description}
          link={project.link}
        />
      ))}
    </div>
  );
};

export default Portfolio;
