import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/Navbar.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [themeMenuOpen, setThemeMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleThemeMenu = () => {
    setThemeMenuOpen(!themeMenuOpen);
  };

  const handleColorChange = (colorType, color) => {
    document.documentElement.style.setProperty(`--${colorType}-color`, color);
  };

  return (
    <nav className="navbar">
      <div className="logo">Ideas On Work</div>
      <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/about">About</Link></li>
        <li><Link to="/portfolio">Portfolio</Link></li>
        <li><Link to="/contact">Contact</Link></li>
        <li><button className="theme-button" onClick={toggleThemeMenu}>.</button></li>
      </ul>
      <button className="menu-toggle" onClick={toggleMenu}>☰</button>
      {themeMenuOpen && (
        <div className="theme-menu">
          <div className="theme-section">
            <h4>Text Colors</h4>
            <div className="color-palette">
              <div className="color-option" style={{ backgroundColor: 'MediumBlue' }} onClick={() => handleColorChange('text', 'MediumBlue')}></div>
              <div className="color-option" style={{ backgroundColor: 'Red' }} onClick={() => handleColorChange('text', 'Red')}></div>
              <div className="color-option" style={{ backgroundColor: 'ForestGreen' }} onClick={() => handleColorChange('text', 'ForestGreen')}></div>
              <div className="color-option" style={{ backgroundColor: 'Indigo' }} onClick={() => handleColorChange('text', 'Indigo')}></div>
              {/* <div className="color-option" style={{ backgroundColor: '#9999ff' }} onClick={() => handleColorChange('text', '#9999ff')}></div>
              <div className="color-option" style={{ backgroundColor: '#bfff80' }} onClick={() => handleColorChange('text', '#bfff80')}></div> */}
            </div>
          </div>
          <div className="theme-section">
            <h4>Background Colors</h4>
            <div className="color-palette">
              <div className="color-option" style={{ backgroundColor: '#d3dcf8' }} onClick={() => handleColorChange('background', '#d3dcf8')}></div>
              <div className="color-option" style={{ backgroundColor: '#ffcccc' }} onClick={() => handleColorChange('background', '#ffcccc')}></div>
              <div className="color-option" style={{ backgroundColor: '#d6f5d6' }} onClick={() => handleColorChange('background', '#bfff80')}></div>
              <div className="color-option" style={{ backgroundColor: '#eaccff' }} onClick={() => handleColorChange('background', '#eaccff')}></div>
              {/* <div className="color-option" style={{ backgroundColor: '#0000ff' }} onClick={() => handleColorChange('background', '#0000ff')}></div>
              <div className="color-option" style={{ backgroundColor: '#008000' }} onClick={() => handleColorChange('background', '#008000')}></div> */}
            </div>
          </div>
        </div>
      )}
      <div className={`dropdown-menu ${isOpen ? 'open' : ''}`}>
        <Link to="/" onClick={toggleMenu}>Home</Link>
        <Link to="/about" onClick={toggleMenu}>About</Link>
        <Link to="/portfolio" onClick={toggleMenu}>Portfolio</Link>
        <Link to="/contact" onClick={toggleMenu}>Contact</Link>
      </div>
    </nav>
  );
}

export default Navbar