/* 
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
    
        Hello, This is new website under construction! Certain changes are made! This is version v1!
      </header>
    </div>
  );
}

export default App;
 */

// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
 import Navbar from './components/Navbar';
 import Home from './components/Home';
 import Portfolio from './components/Portfolio';
//import Project1 from './components/Project1';
//import Project2 from './components/Project2';
/*import About from './pages/About';
import Portfolio from './pages/Portfolio';
import Contact from './pages/Contact'; */

function App() {
  return (
    <Router>
      <Navbar />
          <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/portfolio" element={<Portfolio />} />
      {/*  <Route path="/about" element={<About />} />
        
        <Route path="/contact" element={<Contact />} /> */}
      </Routes>
    </Router> 
  );
}

export default App;
