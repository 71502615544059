// components/ProjectCard.js
import React from 'react';
import '../css/Portfolio.css';

const ProjectCard = ({ title, description, link }) => (
  <div className="project-card">
    {/* <h3>{title}</h3>
    <p>{description}</p>
    <a href={link} class="details-button">View Project</a>
    */}
    <div class="project-title">{title}</div>
    <p class="project-description">{description}</p>
    <button class="details-button">Details</button>
  </div>
);

export default ProjectCard;
